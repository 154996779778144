import React from "react";
import AddToDo from "../components/AddToDo";
import TodoList from "../components/ToDoList";
import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  where
} from "firebase/firestore";
import { db } from "../firebase";
import '../components/AddToDo.css'

function ToDoPage() {
  const [todos, setTodos] = React.useState([]);

  React.useEffect(() => {
    const q = query(collection(db, "todolist"), where("user", "==", localStorage.getItem("uid")));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let todosArray = [];
      querySnapshot.forEach((doc) => {
        todosArray.push({ ...doc.data(), id: doc.id });
      });
      setTodos(todosArray);
    });
    return () => unsub();
  }, []);

  const handleEdit = async (todo, title) => {
    await updateDoc(doc(db, "todolist", todo.id), { title: title });
  };
  const toggleComplete = async (todo) => {
    await updateDoc(doc(db, "todolist", todo.id), { completed: !todo.completed });
  };
  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "todolist", id));
  };
  return (
    <div>
      <div className="form_container">
        <AddToDo />
      </div>
      <div className="todo_container">
        {todos.map((todo) => (
          <TodoList
            key={todo.id}
            todo={todo}
            toggleComplete={toggleComplete}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ))}
      </div>
    </div>
  );
}
export default ToDoPage;