import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import './CalendarPage.css';
import { db } from "../firebase";
import { Navigate } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";


function CalendarPage() {
 
    const [calendarDatas, setCalendarDatas] = useState([])

    useEffect(()=>{
        const q = query(collection(db, "calendar"), where("user", "==", localStorage.getItem("uid")));

        onSnapshot(q, (snapshot) =>{
            let calendarData = []
            snapshot.docs.forEach((doc) => {
                calendarData.push({ ...doc.data(), id: doc.id})
            });
            console.log(calendarData)
            setCalendarDatas(calendarData)
            console.log(calendarDatas)
        })
    },[])

    if(!localStorage.getItem("uid")){
        return <Navigate to="/signin"/>
    }
    // const [txtArea1V, setTxtArea1V] = useState('')


  return (
    <div className="calendarTable">
        <div className='row justify-content-center'>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Isnin</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Selasa</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Rabu</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Khamis</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Jumaat</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Sabtu</p>
            </div>
            <div className="col-1 tblHeader">
                <p className='txtHeader'>Ahad</p>
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className="col-1 tblContent">
                <p className='txtDate'>28 Syaaban</p>
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>29 Syaaban</p>
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>30 Syaaban</p>
                <p className='txtDate'></p>
                
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>1 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 1).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>2 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 2).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>3 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 3).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>4 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 4).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className="col-1 tblContent">
                <p className='txtDate'>5 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 5).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>6 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 6).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>7 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 7).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>8 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 8).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>9 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 9).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>10 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 10).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>11 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 11).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className="col-1 tblContent">
                <p className='txtDate'>12 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 12).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>13 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 13).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>14 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 14).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>15 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 15).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>16 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 16).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>17 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 17).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>18 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 18).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className="col-1 tblContent">
                <p className='txtDate'>19 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 19).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>20 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 20).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>21 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 21).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>22 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 22).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>23 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 23).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>24 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 24).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>25 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 25).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
        </div>
        <div className='row justify-content-center'>
            <div className="col-1 tblContent">
                <p className='txtDate'>26 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 26).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>27 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 27).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>28 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 28).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>29 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 29).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>30 Ramadhan</p>
                {calendarDatas.filter(data => data.day === 30).map(filtereddata =>(<textarea className='txtInput form-control' rows='3' value={filtereddata.content}/>))}
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>1 Syawal</p>
            </div>
            <div className="col-1 tblContent">
                <p className='txtDate'>2 Syawal</p>
            </div>
        </div>
    </div>
  );
}

export default CalendarPage;