import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import CalendarPage from './pages/CalendarPage';

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/signin' element={<SignInPage/>} />
          <Route path='/calendar' element={<CalendarPage/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;