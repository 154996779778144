// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz-7F6dXIzxBzK9ZcztFQf1xeEsb6aS0w",
  authDomain: "ramadhan-8ab93.firebaseapp.com",
  projectId: "ramadhan-8ab93",
  storageBucket: "ramadhan-8ab93.appspot.com",
  messagingSenderId: "755104144772",
  appId: "1:755104144772:web:42ea607aea8fe5675dd1d1",
  measurementId: "G-60B7D3WTDV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export {auth, provider, db};