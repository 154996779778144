import React, { useEffect, useState } from "react";
import {auth, db} from "../firebase";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Navigate, useNavigate, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';
import calendar from '../assets/calendar.png';
import ToDoPage from "./ToDoPage";

function HomePage(){

    const navigate = useNavigate();
    const [hadith, setHadith] = useState([]);

    const handleLogOut = () =>{
        auth.signOut()
        localStorage.clear()
        navigate("/signin");
    }

    useEffect(() => {

        var randomNo = (Math.floor(Math.random() * 3))
        var q = query(collection(db, "hadith"), where("no", "==", randomNo));

        onSnapshot(q, (snapshot) =>{
            let hadithData = []
            snapshot.docs.forEach((doc) => {
                hadithData.push({ ...doc.data(), id: doc.id})
            });
            setHadith(hadithData)
            
        })
    }, []);


    if(!localStorage.getItem("uid")){
        return <Navigate to="/signin"/>
    }

    return(
        <div className="homepage">
            <h1 className="txtTitle">DIARI RAMADHAN</h1>
            <div className="row">
                <div className="col-sm-4 ctrContainer">
                    <h2 className="txtTitle">Selamat Datang , {localStorage.getItem("name")}</h2>
                    <Link to="/calendar"><img src={calendar} className="icon" alt="an icon of calendar"/></Link><br/>
                    <p>Kalendar Ramadhan</p><br/>
                    <button className="btnz" onClick={handleLogOut}>Log Keluar</button>
                </div>
                <div className="col-sm-8">
                    <div className="dailyHadithContainer">
                        <h2 className="txtTitle">Hadis Berkaitan Puasa</h2>
                        {hadith.map((data) => (
                            <div className="hadithContainer">
                                <h4 className='txtTitle'>{data.title}</h4>
                                <p className="hadithDesc">{data.description}</p>
                                <p>{data.content}</p>
                                <p className="hadithRiwayah">-{data.riwayah}-</p>
                            </div>
                        ))}
                        <p></p>
                    </div>
                    <div className="row mt-40">
                            <div className="col-sm-4">
                                <h2 className='txtTitle'>Senarai To-Do</h2>
                                <ToDoPage/>
                            </div>
                            <div className="col-sm-4">
                                <h2 className='txtTitle'>Cabaran 30 Hari</h2>
                            </div>
                            <div className="col-sm-4">
                                <h2 className='txtTitle'>Doa -Doa Pilihan</h2>
                            </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default HomePage;